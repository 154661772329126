@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: "Nunito", sans-serif;
    background-color: #F5F4F6;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
    behavior: smooth;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #01435B;
    behavior: smooth;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #05AAC4;
  }

  .myshadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }